import React, { useState } from "react";
import axios from "axios";
import "./styles.css"; 

const TaskForm = ({ onTaskAdded }) => {
  const [task, setTask] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newTask = { task, time, date, description };
      await axios.post("http://inboxpunk.fun/get_tasks", newTask);
      onTaskAdded();
      setTask("");
      setTime("");
      setDate("");
      setDescription("");
    } catch (error) {
      console.error("Ошибка при добавлении задачи:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="task-form">
      <h2>Добавить задачу</h2>
      <input
        type="text"
        placeholder="Задача"
        value={task}
        onChange={(e) => setTask(e.target.value)}
        required
        className="input-field"
      />
      <input
        type="time"
        value={time}
        onChange={(e) => setTime(e.target.value)}
        required
        className="input-field"
      />
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required
        className="input-field"
      />
      <textarea
        placeholder="Описание"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="textarea-field"
      ></textarea>
      <button type="submit" className="submit-button">
        Добавить задачу
      </button>
    </form>
  );
};

export default TaskForm;
