import React from "react";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import "./styles.css";

const TaskList = ({ tasks }) => {
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "dd.MM.yyyy", { locale: ru });
    } catch {
      return dateString;
    }
  };

  return (
    <div className="task-list-container">
      <h1>Список задач</h1>
      <ul className="task-list">
        {tasks.map((task, index) => (
          <li key={index} className="task-item">
            <strong className="task-title">{task.task}</strong> —{" "}
            <span className="task-date">
              {formatDate(task.date)} {task.time}
            </span>
            <p className="task-description">{task.description}</p>
            <p className={`task-status ${task.completed ? "completed" : "pending"}`}>
              {task.completed ? "Выполнено" : "Не выполнено"}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskList;
