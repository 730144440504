import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import "./styles.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Получаем секретный ключ с сервера
    const fetchSecretKey = async () => {
      try {
        const response = await axios.get("http://inboxpunk.fun/secretKey");
        setSecretKey(response.data.secretKey);
      } catch (error) {
        console.error("Ошибка при получении секретного ключа", error);
      }
    };
    fetchSecretKey();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    // Шифруем логин и пароль с использованием полученного секретного ключа
    const encryptedUsername = CryptoJS.AES.encrypt(username, secretKey).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();

    try {
      const response = await axios.post("http://inboxpunk.fun/p_login", {
        username: encryptedUsername,
        password: encryptedPassword,
      });
      if (response.data.success) {
        localStorage.setItem("loggedIn", true);
        window.location.reload();
      } else {
        setError("Неверный логин или пароль");
      }
    } catch (error) {
      setError("Ошибка при входе");
    }
  };

  return (
    <div className="login-page">
      <h2 className="form-title">Вход</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          placeholder="Логин"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="input-field"
        />
        <button type="submit" className="submit-button">
          Войти
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default LoginPage;
