import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import TaskManager from "./TaskManager";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null); // null для состояния загрузки

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch("http://inboxpunk.fun/checkSession", { credentials: "include" }); // Включаем куки
        if (response.ok) {
          const data = await response.json();
          setIsLoggedIn(data.loggedIn);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Ошибка при проверке сессии:", error);
        setIsLoggedIn(false);
      }
    };

    checkSession();
  }, []);

  if (isLoggedIn === null) {
    return <div>Загрузка...</div>; // Пока сессия проверяется
  }

  return (
    <Router>
      <div>
        <h1>Ежедневник</h1>
        <Routes>
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/tasks" replace /> : <LoginPage />}
          />
          <Route
            path="/tasks"
            element={isLoggedIn ? <TaskManager /> : <Navigate to="/login" replace />}
          />
          <Route path="*" element={<Navigate to={isLoggedIn ? "/tasks" : "/login"} replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
